import React from "react"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles} from "@material-ui/core";

const predictionTypes = [
  { value: "1", text: "ブドウシャインマスカット 果実着色" },
  { value: "2", text: "かき果実着色" },
  { value: "3", text: "うめ「露茜」果実着色" },
];

const useStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: "white",//theme.palette.background.paper,
		border: '2px solid #000',
		padding: "28px 5vw 5vh 5vw"
	},
	modal: {
	  display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'center',
	},
}))

const PredictionResultModal = ({open, onClose, type,jukudo,result,croppedImageUrl,poster}) => {
	const classes = useStyles();
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={ onClose }
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
					timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<h2>診断結果</h2>
					
					<div>
						{croppedImageUrl && (
							<div style={{paddingLeft: "5vw", paddingRight: "5vw"}}>
								<img id="cropImage" alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
							</div>
						)}
						<List dense={true}>
              
							<ListItem>
								<ListItemText	primary="種類" />{predictionTypes[parseInt(type)-1]?.text}
							</ListItem>
							<ListItem>
								<ListItemText	primary="予想" />{jukudo}%
							</ListItem>
							<ListItem>
								<ListItemText	primary="結果" />{result}%
							</ListItem>
							{poster &&
								<ListItem>
									<ListItemText	primary="投稿者" />{poster}
								</ListItem>
							}
            </List>
					</div>
				</div>
			</Fade>
		</Modal>
	)
}
export default PredictionResultModal