import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import PredictionResultModal from './PredictionResultModal'

const useStyles = makeStyles(() => ({
  imageList: {
    width: "100%",
    height: "6ovh",
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const PostsList = ({breakpoints,user}) => {
  const classes = useStyles();
  const cols = breakpoints.mobile ? 2 : 5;
  const [postsData, setPostsData] = useState([]);
  const [postData, setPostData] = useState({
    type: null,
    jukudo: null,
    result: null,
    croppedImageUrl: null,
    poster: null
  });
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    var post = postsData[e.currentTarget.value]
    setPostData({
      jukudo: parseInt(post["expected"]),
      type: post["type"],
      result: parseInt(post["result"]),
      croppedImageUrl: `${process.env.GATSBY_NMT_URL}/images/${post["image_name"]}`,
      poster: post["user_name"]
    })
    setOpen(true);
  }
  
  const handleClose = () => setOpen(false);

  // 追加
	useEffect(() => {

		async function fetchData() {

      const options = {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json, text/plain, */*',
        },
      };

			await fetch(`${process.env.GATSBY_NMT_URL}/histories/index?code=${user.code}`, options)
        .then(response => response.json())
        .then(data => {
          setPostsData(data)
          console.log(`${data}`)
        })    
        .catch(error => {
          console.log(error)
          alert(error)
        })
    }
    
		fetchData();
	},[user.code])

  return (
    <div style = {{paddingTop: "3vh", paddingBottom: "3vh"}}>
      <PredictionResultModal 
        open= {open}
        onClose={handleClose} 
        type={postData.type} 
        jukudo={postData.jukudo} 
        result={postData.result} 
        croppedImageUrl={postData.croppedImageUrl}
        poster={postData.poster}
      />
      <ImageList rowHeight={180} className={classes.imageList} cols={cols}>
        {postsData.map((post,index) => (
          <ImageListItem key={post.image_name}>
            <img src={`${process.env.GATSBY_NMT_URL}/images/${post.image_name}`} alt={post.image_name} />
            <ImageListItemBar
              title={`${parseInt(post.result)}(${parseInt(post.expected)})%`}
              subtitle={<span>by: {post.user_name}</span>}
              actionIcon={
                <IconButton value={index} aria-label={`info about ${post.image_name}`} className={classes.icon} onClick={handleOpen}>
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

export default PostsList