
import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import Button from '@material-ui/core/Button';
import 'react-image-crop/dist/ReactCrop.css'

export default class ImageCrop extends PureComponent {
  state = {
    src: null,
    crop: {
      x: 0,
      y: 0,
      width: 300,
      aspect: 1 / 1,
    },
  }


  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.props.setCroppedImageUrl(croppedImageUrl);
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = 300;
    canvas.height = 300;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  render() {
    const { crop, src } = this.state;

    return (
      <div className="ImageCrop">
        <div><Button
					variant="contained" color="secondary" component="label"
				>
					写真をとる
					<input id={this.props.id} {...this.props.register } type="file" accept="image/*" onChange={this.onSelectFile} hidden/>
				</Button></div>
        {src && (
          <div style={{paddingLeft: "5vw", paddingRight: "5vw", paddingTop: "1vh"}}>
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </div>
        )}
        {this.props.croppedImageUrl && (
          <div style={{paddingLeft: "5vw", paddingRight: "5vw"}}>
            <img id="cropImage" alt="Crop" style={{ maxWidth: '100%' }} src={this.props.croppedImageUrl} />
          </div>
        )}
      </div>
    );
  }
}
