import React, { useState } from "react";
import { Link } from 'gatsby';
import ImageCrop from '../imageCrop'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { Container, makeStyles} from "@material-ui/core";
import { useForm } from 'react-hook-form'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PredictionResultModal from '../PredictionResultModal'

const predictionTypes = [
  { value: "1", text: "ブドウシャインマスカット 果実着色" },
  { value: "2", text: "かき果実着色" },
  { value: "3", text: "うめ「露茜」果実着色" },
];

// スタイルの記述をする
const useStyles = makeStyles((theme) => ({
  prediction: {
    paddingBottom: "5vh"
  },
  content: {
    marginTop: 30, 
    display: 'grid'
  },
  form: {
    marginTop: 50,
    marginBottom: 100,
  },
  error: {
    color: "#f44336"
  },
  checkGroup: {
    marginLeft: 20,
    marginRight: 2
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  
}));

const Error = ({arg, className}) => {
  return (arg!==undefined && <div className={className} key={arg.type}>{arg.message}</div>)
}


const Prediction = (props) => {

  const [result, setResult] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);


  const onSubmit = async data => {
    setLoaded(true)
    console.log(data);

    const img = document.getElementById('cropImage');
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    canvas.getContext('2d').drawImage(img, 0, 0);
    const dataUrl = canvas.toDataURL('image/png');
    console.log(dataUrl)

    var params = new FormData();
    params.append("user", JSON.stringify(props.user));
    params.append("select", data.type);
    params.append("jukudo", data.jukudo);
    params.append("image", dataUrl);

    //送信用データを設定
		const options = {
			method: 'POST',
			mode: 'cors',
			credentials: 'omit',
			body: params,
			headers: {
        'Accept': 'application/json, text/plain, */*',
			},
		};
		
		// 設定したデータをPOST
    await fetch(`${process.env.GATSBY_NMT_URL}/prediction`, options)
    .then(response => response.json())
    .then(data => {
      setResult(data[0].score)
      console.log(`熟度は${data[0].score}%です。`)
    })    
    .catch(error => {
			console.log(error)
			alert(error)
		})
    setLoaded(false)
  }

	const { register, handleSubmit, getValues, formState: { errors }, } = useForm()
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  return (

    <div className={classes.prediction}>
      <Backdrop className={classes.backdrop} open={loaded}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <PredictionResultModal 
          open= {result !== null} 
          onClose ={() => setResult(null)}
          setResult ={setResult} 
          type={getValues('type')} 
          jukudo={getValues('jukudo')} 
          result={result} 
          croppedImageUrl={croppedImageUrl}
        />
        

        <h2 style={{paddingTop: "2vh"}} >
          <Link to="/app/histories/index" style={{float: "right", fontSize: "16px"}}>履歴</Link>
          熟度判定
        </h2>
        
        <form id= "contact_form" onSubmit={handleSubmit(onSubmit)}>
          <Button variant="contained" onClick= {() => window.location.reload()} style={{float: "right", fontSize: "12px"}}>更新</Button>
          果実を撮影して枠で囲って送信してください。
          <div className={classes.content}>
            
              <TextField
                select
                label="調べたいものを選択してください *"
                id="type"
                variant="outlined"
                defaultValue="" 
                error={Boolean(errors.type)}
                {...register("type", { required: "必須入力です" }) }
              >
              {predictionTypes.map((person) => (
                <MenuItem key={person.value} value={person.value}>
                  {person.text}
                </MenuItem>
              ))}
              </TextField>
              <Error className={classes.error} arg={errors.type} />
            
          </div>
          <div className={classes.content}>
            <TextField 
              id="jukudo"
              type="number"
              label="予想熟度（％）"
              variant="outlined"  
              placeholder="70" 
              {...register("jukudo", { required: "必須入力です", max: {value: 100, message: "0〜100％で入力"},min: {value: 0, message: "0〜100％で入力"} }) }
              error={Boolean(errors.jukudo)} 
            />
            <Error className={classes.error} arg={errors.jukudo} />
          </div>
          
          <div className={classes.content}>
            <ImageCrop 
              id="image"
              croppedImageUrl = {croppedImageUrl}
              setCroppedImageUrl = {setCroppedImageUrl}
              error={Boolean(errors.image)}
              register = {register("image", { required: "必須入力です" })}/>
            <Error className={classes.error} arg={errors.image} />
          </div>

          <div className={classes.content}>
            <Button style={{fontSize: "18px"}} variant="contained" color="primary" type="submit">送信</Button>
          </div>
        
        </form>
      
    </Container>


    </div>
  )
};

export default Prediction;