import React, { useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import HistoriesIndex from "../components/app/histories/index"
import Prediction from "../components/app/prediction"
import Seo from "../components/seo"
import Auth from '../components/Auth'
import cognitoAuth from "../services/cognitoAuth"

const App = (props) => {
  const breakpoints = useBreakpoint();
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState({name:'', code: ''})
  const [loaded, setLoaded] = useState(true);

  const handleLogout = () => {
    console.log("サインアウト")
    cognitoAuth.signOut();
    setLoggedIn(false)
  };

	return(
  <Layout location={props.location} breakpoints = {breakpoints} isLoggedIn = {isLoggedIn} handleLogout = {handleLogout}>
    <Seo title="診断" />
    <Auth location={props.location} setLoggedIn={setLoggedIn} user={user} setUser={setUser} breakpoints = {breakpoints} setLoaded={setLoaded} loaded={loaded}>
      <Router>
        <PrivateRoute path="/app/" component={Prediction} user={user} isLoggedIn = {isLoggedIn}/>
        <PrivateRoute path="/app/prediction" component={Prediction} user={user} isLoggedIn = {isLoggedIn}/>
        <PrivateRoute path="/app/histories/index" breakpoints = {breakpoints} component={HistoriesIndex} user={user} isLoggedIn = {isLoggedIn}/>
      </Router>
    </Auth>
    
  </Layout>
	)
}

export default App