import React from "react"
import Container from '@material-ui/core/Container';
import { Link } from 'gatsby';
import PostsList from "../../postsList";

const HistoriesIndex = ({user,breakpoints}) => (
  <Container>
    <Link to="/app/prediction" style={{float: "right", fontSize: "16px"}}>戻る</Link>
    <h1>履歴</h1>
    <PostsList breakpoints = {breakpoints} user={user}/>
  </Container>
)
export default HistoriesIndex