import { CognitoAuth } from 'amazon-cognito-auth-js';

const openid_connect = {
    ClientId: `${process.env.GATSBY_AWS_CLIENT_ID}`,
    AppWebDomain: `${process.env.GATSBY_AWS_WEB_APP_DOMAIN}`,
    TokenScopesArray: [
      'profile',
      'email',
      'openid',
      'aws.cognito.signin.user.admin',
      'phone'
    ],
    RedirectUriSignIn: `${process.env.GATSBY_AWS_REDIRECT_URL_SIGNIN}`,
    RedirectUriSignOut: `${process.env.GATSBY_AWS_REDIRECT_URL_SIGNOUT}`
  }

const cognitoAuth = new CognitoAuth(openid_connect);
export default cognitoAuth

